import { DataTable } from 'primereact/datatable';
import { HeaderTable } from './HeaderTable';
import { useEffect, useState } from 'react';
import { getCareersWithGenerations } from '../../../../services/Careers';
import { NotificationComponent } from '../../../../components/NotificationComponent';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import axios from 'axios';

// Define the type of each item in the array
interface CareerGeneration {
  id: number;
  description: string;
  sis_generation_id: string;
  sis_segment_code: string;
  enabled: boolean;
  career_id: number;
  // Add other properties if necessary
}

export const TableGenerations = () => {
  const { toast, showSuccess, showSuccessEdit } = NotificationComponent();
  const [careersWithGenerations, setCareersWithGenerations] = useState<CareerGeneration[]>([]);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    console.log('Enabled state:', enabled);
  }, [enabled]);

  useEffect(() => {
    reload();
  }, [enabled]);

  const reload = async () => {
    setLoading(true);
    try {
      const data = await getCareersWithGenerations();
      let filteredData = data;
  
      if (enabled) {
        filteredData = data.filter((item: CareerGeneration) => item.enabled);
      }
  
      setCareersWithGenerations(filteredData);
    } catch (error) {
      console.error('Error fetching careers with generations:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const filters = {
    description: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    },
    sis_generation_id: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    },
    sis_segment_code: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    },
    enabled: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    }
  };

  const toggleCommand = (careerId: number, generationId: number) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/careers/${careerId}/generations/${generationId}/toggle`,
        {},
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          }
        }
      )
      .then(() => {
        reload();
      })
      .catch(error => {
        console.error('Error toggling generation:', error);
      });
  };

  const actionToggle = (rowData: CareerGeneration) => {
    return (
      <Button
        icon={rowData.enabled ? 'pi pi-eye' : 'pi pi-eye-slash'}
        className={`p-button-rounded p-button-text ${rowData.enabled ? '' : 'p-button-danger'}`}
        data-te-toggle='tooltip'
        title={rowData.enabled ? 'Ocultar' : 'Mostrar'}
        onClick={() => toggleCommand(rowData.career_id, rowData.id)}
      />
    );
  };
console.log("carrers",careersWithGenerations)
  return (
    <>
      <Toast ref={toast} />
      <DataTable
        value={careersWithGenerations}
        responsiveLayout='stack'
        breakpoint='960px'
        dataKey='id'
        emptyMessage='No se encontraron datos'
        rows={15}
        filters={filters}
        loading={loading}
        filterDisplay='row'
        paginator
        className='shadow-lg shadow-gray-500/30'
        header={<HeaderTable enabled={enabled} setEnabled={setEnabled} />}
      >
        <Column field='id' header='Id' sortable />
        <Column
          field='description'
          filter
          showFilterMenu={false}
          filterPlaceholder='Buscar por generación'
          header='Tabla de generaciones'
          sortable
        />
        <Column
          field='sis_generation_id'
          filter
          showFilterMenu={false}
          filterPlaceholder='Buscar por sis id'
          header='Id de Sis'
          sortable
        />
        <Column
          field='sis_segment_code'
          header='Código de Segmento'
          showFilterMenu={false}
          filter
          filterPlaceholder='Buscar por segmento'
          sortable
        />
        <Column
          body={actionToggle}
          exportable={false}
          style={{ minWidth: '8rem' }}
        />
      </DataTable>
    </>
  );
};
