import { DataTable } from 'primereact/datatable';
import { HeaderTable } from './HeaderTable';
import { useEffect, useState } from 'react';
import { GetDiscountsTable } from '../../../../services';
import { NotificationComponent } from '../../../../components/NotificationComponent';
import { DiscountType } from '../../../../interfaces';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { DialogTableDiscount } from './DialogTableDiscount';
import { DiscountFormProvider, initialValue } from '../context/DiscountFormContext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import axios from 'axios';
import { DialogEditDiscount } from './DialogEditDiscount';

export const TableDiscounts = () => {
  const { toast, showSuccess, showSuccessEdit } = NotificationComponent();
  const [arrDiscounts, setArrDiscounts] = useState<DiscountType[]>([]);
  const [newValue, setNewValue] = useState();
  const [rowSelected, setRowSelected] = useState<any>(null!);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [editValue, setEditValue] = useState<any>();
  const [enabled,setEnabled] = useState(true);
  let { loading, error, reload } = GetDiscountsTable({setListDiscounts: setArrDiscounts, enabled});


  useEffect(() => {
    if (newValue) {
      console.log('NewValue =>', newValue);
      let newArr = arrDiscounts;
      newArr.push(newValue);
      setArrDiscounts(newArr);
    }
  }, [arrDiscounts, newValue]);

  useEffect(() => {
    reload();
  }, [enabled])

  const filters = {
    description: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    },
    enabled: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    }
  };

  const toggleCommand = (pricesTableId: number) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/discounts/${pricesTableId}/toggle`,
        {},
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          }
        }
      )
      .then((response) => {
        reload();
      });
  }

  const actionEdit = (rowData: any) => {
    return(
      <Button
        icon='pi pi-pencil'
        className='p-button-rounded p-button-text p-button-warning '
        data-te-toggle='tooltip'
        title='Editar'
        onClick={() => {
          setRowSelected(rowData);
          //updateForm(rowData);
          setOpenDialogEdit(true);
        }}
      />
    );
  }

  const actionToggle = (rowData: any) => {
    return (
      rowData.enabled ? (<Button
          icon='pi pi-eye'
          className='p-button-rounded p-button-text p-button'
          data-te-toggle='tooltip'
          title='Ocutar'
          onClick={() => toggleCommand(rowData.id)}
        />) :
        (<Button
          icon='pi pi-eye-slash'
          className='p-button-rounded p-button-text p-button-danger '
          data-te-toggle='tooltip'
          title='Ocutar'
          onClick={() => toggleCommand(rowData.id)}
        />)
    );
  }

  const actionToast = (action: string) => {
    switch (action) {
      case 'success':
        showSuccess();
        break;
      case 'edit':
        showSuccessEdit();
        break;

      default:
        break;
    }
  };

  return (<DiscountFormProvider {...initialValue}>
    <>
      <Toast ref={toast}/>
      <DataTable
        value={arrDiscounts}
        responsiveLayout='stack'
        breakpoint='960px'
        dataKey='id'
        emptyMessage={'No se encontraron datos'}
        rows={15}
        filters={filters}
        loading={loading}
        filterDisplay='row'
        paginator
        className='shadow-lg shadow-gray-500/30'
        header={<HeaderTable enabled={enabled} setEnabled={setEnabled} />}
      >
        <Column field='id' header={'Id'} sortable></Column>
        <Column
          field='description'
          filter
          showFilterMenu={false}
          filterPlaceholder={'Buscar por descuento'}
          header={'Tabla de descuentos'}
          sortable
        ></Column>
        <Column
          field='percentage'
          header={'Porcentage'}
          sortable
        ></Column>
        <Column
          header = 'Id de Sis'
          showFilterMenu={false}
          field='sis_discount_motive_id'>
        </Column>
        <Column
          body={actionEdit}
          exportable={false}
          style={{ minWidth: '8rem' }}>
        </Column>
        <Column
          body={actionToggle}
          exportable={false}
          style={{ minWidth: '8rem' }}
        ></Column>
      </DataTable>
      <DialogTableDiscount
        actionToast={actionToast}
        reload={reload} />
      {openDialogEdit && (
        <DialogEditDiscount
          actionToast={actionToast}
          reload={reload}
          id={rowSelected.id}
          open={openDialogEdit}
          close={() => setOpenDialogEdit(false)}
        />
      )}
    </>
  </DiscountFormProvider>
  );

};